<template>
    <v-main>
      <template v-if="!isEditPromo">
          <div class="main-content">
            <Card class="mb-15">
              <template #title>
                <Toolbar>
                  <template #start>
                  </template>
                  <template #end>
                    <Button :disabled="loadingItem" :loading="loadingItem" 
                    :label="$t('create_promocode')" icon="pi pi-plus" 
                    class="p-button-sm p-button-success" @click="$router.push('/promocodes/create')" />
                    </template>
              </Toolbar>
              </template>
              <template #content>
                <v-row tag="section">
                  <v-col>
                </v-col>
              </v-row>
                <v-row tag="section">
                  <v-col>
                    <!-- :rows="cashBox.size" -->
                    <DataTable 
                    responsiveLayout="scroll"
                    :value="promocode" 
                    :rows="10"
                    :loading="loadingFlag" 
                    :paginator="true"
                      stripedRows class="p-datatable-sm footer-table">
                      <template #empty>
                        {{ $t('no_data') }}
                      </template>
                      <Column field="id" header="Id">
                      </Column>
                      <Column field="code" :header="`${$t('name')} (${$t('code')})`">
                      </Column>
                      <Column field="description" :header="$t('description')">
                      </Column>
                      <Column field="expiration_date" :header="$t('expiration_date')" :styles="{'min-width':'12rem'}">
                        <template #body="slotProps">
                          <div>
                            {{ `${slotProps.data.expiration_date.split('T')[0]}, ${slotProps.data.expiration_date.split('T')[1]}` }}
                          </div>
                        </template>
                      </Column>
                      <Column field="original_max_uses" :header="$t('max_uses')" :styles="{'min-width':'10rem'}">
                        <template #body="{data}">
                          <div>
                            {{ `${data.max_uses} / ${data.original_max_uses}`  }}
                          </div>
                        </template>
                      </Column>
                      <Column field="reward" :header="$t('reward')" :styles="{'min-width':'50rem'}">
                        <template #body="slotProps">
                          <div class="d-flex flex-column">
                            <div v-if="slotProps.data.dep_bonus_id !== null">
                              <span class="font-weight-bold">{{$t('dep_bonus')}}:
                               </span> 
                               {{findAndFormatDepBonus(slotProps.data)}}
                              </div> 
                            <div v-if="slotProps.data.free_rounds_bonus_id !== null">
                              <span class="font-weight-bold">{{$t('free_rounds')}}:</span> 
                            {{findAndFormatSpinsBonus(slotProps.data)}}
                          </div>
                          </div>
                        </template>
                      </Column>
                      
                      <Column :header="$t('actions')" :exportable="false" :styles="{'min-width':'8rem'}">
                        <template #body="{data}">
                            <div class="d-flex align-center">
                              <Button icon="pi pi-pencil" :disabled="data.loadingItem" :loading="data.loadingItem" class="p-button-sm p-button-rounded p-button-warning mr-2" @click="toOpenEdit(data)" />
                              <Button icon="pi pi-trash" :disabled="data.loadingItem" :loading="data.loadingItem" class="p-button-sm p-button-rounded p-button-danger" @click="toOpenDelete(data)" />
                          
                            </div>
                          </template>
                    </Column>
                    </DataTable>
                  </v-col>
                </v-row>
            </template>
            </Card>
          
          </div>
            </template>
            <edit-promocode :promoInfo="editPromo" v-if="isEditPromo"/>
    
    </v-main>
</template>

<script>
import { mapGetters } from 'vuex';
import EditPromocode from './EditPromocode.vue';
    export default {
    name: 'Promocodes',
    components: { 
      EditPromocode
     },
    data() {
        return {
            loadingFlag: true,
            loadingItem: false,
        };
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.$store.commit('setPageName', 'promo');
            vm.$store.commit('promocodes/SET_EDIT_PROMO', false);
        });
    },
    async mounted() {
        if (this.promocode.length === 0) {
          const err = await this.$store.dispatch('promocodes/awaitGetPromocodes');
          err ? this.$toast.add({
            severity: 'error', summary: this.$t("just_error"), life: 4000
          }) : '';
        }
        if (this.depositBonuses.items.length === 0) {
            await this.$store.dispatch('bonuses/awaitGetDepositBonuses');
        }
        if (this.spinsBonuses.items.length === 0) {
            await this.$store.dispatch('bonuses/awaitGetSpinsBonuses');
        }
            this.loadingFlag = false;
    },
    computed: {
        ...mapGetters({
            promocode: 'promocodes/getPromocodes',
            spinsBonuses: 'bonuses/getSpinsBonuses',
            depositBonuses: 'bonuses/getDepositBonuses',
        }),
        isEditPromo() {
            return this.$store.state.promocodes.isEditPromo;
        },
    },
    methods: {
        formatDepositBonus(bonus) {
            if (!bonus || !bonus.currency_limits) {
                return;
            }
            const formattedBonus = Object.entries(bonus.currency_limits)
                .map(([currency, limits]) => ` ${currency}: ${this.$t('min_dep')}: ${limits.min_dep}, ${this.$t('max_dep')}: ${limits.max_dep}`);
            const restOfName = [
                this.$t('bonus_percent') + ': ' + bonus.bonus_percent,
                this.$t('wager') + ': ' + bonus.wager,
                this.$t('type') + ': ' + this.$t(bonus.type),
            ];
            return [...restOfName, ...formattedBonus].join(', ');
        },
        formatSpinsBonus(bonus) {
            if (!bonus || !bonus.currency_bet) {
                return;
            }
            const formattedBonus = Object.entries(bonus.currency_bet)
                .filter(([currency, value]) => value !== 0)
                .map(([currency, value]) => this.$t('bet') + ': ' + value + ' ' + currency);
            const restOfName = [
                this.$t('game_name') + ': ' + bonus.game_name,
                this.$t('provider') + ': ' + bonus.provider_name,
                this.$t('spin') + ': ' + bonus.count,
            ];
            const restOfLast = [
                this.$t('wager') + ': ' + bonus.wager,
                this.$t('type') + ': ' + this.$t(bonus.type),
            ];
            return [...restOfName, ...restOfLast, ...formattedBonus].join(', ');
        },
        findAndFormatDepBonus(bonusInfo) {
            if (bonusInfo.dep_bonus_id) {
                let foundBonus;
                foundBonus = this.depositBonuses.items.find(bonus => bonus.id === bonusInfo.dep_bonus_id);
                return this.formatDepositBonus(foundBonus);
            }
        },
        findAndFormatSpinsBonus(bonusInfo) {
            if (bonusInfo.free_rounds_bonus_id) {
                let foundBonus;
                foundBonus = this.spinsBonuses.items.find(bonus => bonus.id === bonusInfo.free_rounds_bonus_id);
                return this.formatSpinsBonus(foundBonus);
            }
        },
        toOpenEdit(promocode) {
            this.editPromo = {
                id: promocode.id,
                code: promocode.code,
                description: promocode.description,
                is_active: promocode.is_active,
                dep_bonus_id: promocode.dep_bonus_id,
                free_rounds_bonus_id: promocode.free_rounds_bonus_id,
                max_uses: promocode.max_uses,
                expiration_date: new Date(promocode.expiration_date),
                //expiration_time: promocode.expiration_date.split('T')[1],
            };
            this.$store.commit('promocodes/SET_EDIT_PROMO', true);
            this.$store.commit('setPageName', 'promo_edit');
        },
        async toOpenDelete(promocode) {
            this.loadingItem = true;
            promocode.loadingItem = true;
            const promoId = promocode.id;
            try {
              await this.$store.dispatch('promocodes/deletePromocode', promoId);
              this.$toast.add({
              severity: 'info', summary: this.$t("promo_deleted"), life: 4000
            })
            } catch (error) {
              this.$toast.add({
          severity: 'error', summary: this.$t("just_error"), life: 4000
        })
              
            } finally {
              this.loadingItem = false;
              promocode.loadingItem = false;
            }
        },
    },
}
</script>

<style lang="scss" scoped>

</style>