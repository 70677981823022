<template>
    <div class="mt-1 px-2 mx-auto" style="max-width: 1000px;">
          <Card class="card-custom card-block">
            <template #title>
              <div class="column-wrapper align-center">
                <div class="column-three text-left">
                  <Button @click="goPromoList" icon="pi pi-chevron-left" class="p-button-danger px-8"/>
                </div>
                  <h4 class="c-black flex-1 column-three">{{$t('promo_data')}}</h4>
                  <div class="column-three"></div>
              </div>
            </template>
            <template #content>
                <!--  -->
                <ValidationObserver ref="observer" v-slot="{ invalid, validate }"> 
                  <Card class="card-custom form-block">
                    <!--  -->
                    <template #content>
                    <div class="space-y-1">
                      <ValidationProvider
                  :name="$t('code')"
                  rules="required|min3"
                  v-slot="{ errors, validate, validated }"
                > 
                  <h4 class="c-text white-space title-input">{{ $t('code') }}<span class="c-red">*</span>:</h4>
                  <div class="wrapper-input">
                    <InputText 
                    :maxlength="12"
                    v-model="editPromo.code" 
                    class="p-inputtext-sm fw"
                    :class="{ 'p-invalid' : errors.length > 0 }"
                      :placeholder="$t('code')" 
                      
                      />
                    <span v-if="errors.length" class="p-error mt-1 font-weight-bold">{{ errors[0] }}</span>
                  </div>
              </ValidationProvider>
                      <ValidationProvider
                  :name="$t('description')"
                  rules="required"
                  v-slot="{ errors, validate, validated }"
                > 
                  <h4 class="c-text white-space title-input">{{ $t('description') }}:</h4>
                  <div class="wrapper-input">
                    <InputText 
                    :maxlength="12"
                    v-model="editPromo.description" 
                    class="p-inputtext-sm fw"
                    :class="{ 'p-invalid' : errors.length > 0 }"
                      :placeholder="$t('description')" 
                      
                      />
                    <span v-if="errors.length" class="p-error mt-1 font-weight-bold">{{ errors[0] }}</span>
                  </div>
              </ValidationProvider>
                      <ValidationProvider
                  :name="$t('max_uses')"
                  rules="required"
                  v-slot="{ errors, validate, validated }"
                > 
                  <h4 class="c-text white-space title-input">{{ $t('max_uses') }}<span class="c-red">*</span>:</h4>
                  <div class="wrapper-input">
                    <InputText 
                    :maxlength="12"
                    v-model="editPromo.max_uses" 
                    class="p-inputtext-sm fw"
                    :class="{ 'p-invalid' : errors.length > 0 }"
                      :placeholder="$t('max_uses')" 
                      
                      />
                    <span v-if="errors.length" class="p-error mt-1 font-weight-bold">{{ errors[0] }}</span>
                  </div>
              </ValidationProvider>
              <ValidationProvider
                  :name="$t('expiration_date')"
                  rules="required"
                  v-slot="{ errors, validate, validated }"
                > 
                  <h4 class="c-text white-space title-input">{{ $t('expiration_date') }}<span class="c-red">*</span>:</h4>
                  <div class="wrapper-input">
                    <Calendar id="time24" v-model="editPromo.expiration_date" 
                  :minDate="today"
                  :showTime="true" :showSeconds="true" />
                    <span v-if="errors.length" class="p-error mt-1 font-weight-bold">{{ errors[0] }}</span>
                  </div>
              </ValidationProvider>
              <span> 
                  <h4 class="c-text white-space title-input">{{ $t('dep_bonus') }}:</h4>
                  <div class="wrapper-input d-flex">
                    <!-- <v-autocomplete
                      hide-details="auto"
                      :items="dep_bonuses"
                      :no-data-text="$t('no_data')"
                      v-model="editPromo.dep_bonus_id"
                      outlined
                      dense
                      color="var(--main-flamingo)"
                      :label="$i18n.t('choose_deposit_bonus')"
                      :menu-props="{contentClass: 'main-list menu-wrap'}"
                      >
                      </v-autocomplete> -->
                      <Dropdown
                      optionLabel="text"
                      optionValue="value"
                    v-model="editPromo.dep_bonus_id"
                    :options="dep_bonuses"
                    class="custom-dropdown"
                    style="flex: 1 1 auto"
                    :placeholder="$t('choose_deposit_bonus')"
                    :filter="true"
                    :emptyFilterMessage="$t('no_data')"
                  >
                  </Dropdown>
                  </div>
              </span>
              <span> 
                  <h4 class="c-text white-space title-input">{{ $t('spins_bonus') }}:</h4>
                  <div class="wrapper-input d-flex">
                    <!-- <v-autocomplete
                              hide-details="auto"
                              :items="fr_bonuses"
                              :no-data-text="$t('no_data')"
                              v-model="editPromo.free_rounds_bonus_id"
                              outlined
                              dense
                              color="var(--main-flamingo)"
                              :label="$i18n.t('choose_deposit_freespins')"
                              :menu-props="{contentClass: 'main-list menu-wrap'}"
                              >
                      </v-autocomplete> -->
                      <Dropdown
                      optionLabel="text"
                      optionValue="value"
                    v-model="editPromo.free_rounds_bonus_id"
                    :options="fr_bonuses"
                    style="flex: 1 1 auto"
                    class="custom-dropdown "
                    :placeholder="$t('choose_freespins_bonus')"
                    :filter="true"
                    :emptyFilterMessage="$t('no_data')"
                  >
                   <!-- <template #value="slotProps">
                       <div v-if="slotProps.value">{{ slotProps.value }} ({{ slotProps.value.total_players }})</div>
                          <span v-else> 
                            {{ slotProps.placeholder }}
                          </span> 
                    </template>
                    <template #option="slotProps">
                      {{ slotProps.option.name }} ({{
                        slotProps.option.total_players
                      }})
                    </template>-->
                  </Dropdown>
                  </div>
              </span>
              <transition name="error-message">
                <span class="error-message _no-bonus" v-if="noBonusFlag">
                  {{ $t('require_bonus') }}
                </span>
              </transition>
            </div>
            </template>
          </Card>
                    <Button @click="handleSubmit(validate)" 
               :disabled="loadingFlag || formLoading" 
                :loading="loadingFlag || formLoading"
                         :label="$t('edit')"
                         icon="pi pi-save"
               class="fw p-button-sm p-button-success mt-2" />
                </ValidationObserver>
                  </template>
          </Card>
</div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    name: 'EditPromocode',
    computed: {
        ...mapGetters({
            spinsBonuses: 'bonuses/getSpinsAllListBonuses',
            depositBonuses: 'bonuses/getDepositAllListBonuses',
        }),
    
    },
    props:{
        promoInfo: Object,
    },
    watch: {
    },
    data() {
        return {
            formLoading: false,
            dateMenu: false,
            timeMenu: false,
            isPromoValid: true,
            loadingFlag: true,
            currentLocale: this.$i18n.locale || 'en',
            today: new Date(),
            editPromo: {
                  expiration_date: "",
                  expiration_time: "",
                },
            noTimeFlag: false,
            noBonusFlag: false,
            fr_bonuses:[],
            dep_bonuses:[],
        }
    },
    beforeRouteEnter(to, from, next){
    next(vm => {
      vm.$store.commit('setPageName', 'promo_edit')
    });
    },
    async mounted() {
        this.editPromo = this.promoInfo;
        if (this.depositBonuses.items.length === 0 ) {
          await this.$store.dispatch('bonuses/awaitGetDepositBonuses');
        }
        if (this.spinsBonuses.items.length === 0) {
          await this.$store.dispatch('bonuses/awaitGetSpinsBonuses');
        }
        this.dep_bonuses = [
          { text: this.$t('none'), value: 0 },
          ...this.depositBonuses.items.map(bonus => ({
          text: this.formatDepositBonus(bonus),
          value: bonus.id
        }))
        ];
        this.fr_bonuses = [
        { text: this.$t('none'), value: 0 }, 
        ...this.spinsBonuses.items.map(bonus => ({
          text: this.formatSpinsBonus(bonus),
          value: bonus.id
        }))
        ],
        this.loadingFlag = false;
    },
    methods: {
        goPromoList(){
            this.$store.commit('promocodes/SET_EDIT_PROMO', false);
            this.$store.commit('setPageName', 'promo');
        },
          formatDepositBonus(bonus){
            if (!bonus || !bonus.currency_limits) {
            return; 
          }
        const formattedBonus = Object.entries(bonus.currency_limits)
            .map(([currency, limits]) => ` ${currency}: ${this.$t('min_dep')}: ${limits.min_dep}, ${this.$t('max_dep')}: ${limits.max_dep}`)
        const restOfName = [
          this.$t('bonus_percent') + ': ' + bonus.bonus_percent,
          this.$t('wager') + ': ' + bonus.wager,
          this.$t('type') + ': ' + this.$t(bonus.type),
        ];

        return [...restOfName, ...formattedBonus].join(', ');
      },
            formatSpinsBonus(bonus) {
              if (!bonus || !bonus.currency_bet) {
            return; 
          }
                const formattedBonus = Object.entries(bonus.currency_bet)
                  .filter(([currency, value]) => value !== 0)
                  .map(([currency, value]) => this.$t('bet') + ': ' + value + ' ' + currency);
              const restOfName = [
                this.$t('game_name') + ': ' + bonus.game_name,
                this.$t('provider') + ': ' + bonus.provider_name,
                this.$t('spin') + ': ' + bonus.count,
              ];
              const restOfLast = [
                this.$t('wager') + ': ' + bonus.wager,
                this.$t('type') + ': ' + this.$t(bonus.type),
              ];
                        
              return [...restOfName, ...restOfLast, ...formattedBonus].join(', ');
},
padZero(num) {
      return num.toString().padStart(2, '0');
    },
    formatDateToISOString(date) {
      const year = date.getFullYear();
      const month = this.padZero(date.getMonth() + 1); // Months are zero-based in JS
      const day = this.padZero(date.getDate());
      const hours = this.padZero(date.getUTCHours());
      const minutes = this.padZero(date.getUTCMinutes());
      const seconds = this.padZero(date.getUTCSeconds());

      return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}Z`;
    },
    handleSubmit(validate){
      validate().then(valid =>{
        if (valid) {
          this.editPromocode();
        }
      })
    },
            async editPromocode(){
              this.formLoading = true;
              this.noBonusFlag = false;
              const edit = this.editPromo;
              if (edit.dep_bonus_id === '' && edit.free_rounds_bonus_id === '') {
                this.formLoading = false;
                this.noBonusFlag = true;
                return
              }
              const dateTimeString = this.formatDateToISOString(edit.expiration_date);
              const promoData = {
                code: edit.code,
                description: edit.description,
                is_active: edit.is_active,
                expiration_date: dateTimeString,
                max_uses: edit.max_uses,
              };
              try {
                await this.$store.dispatch('promocodes/awaitUpdatePromocode', promoData);
                this.$toast.add({
            severity: 'success', summary: this.$t("promo_success_updated"), life: 4000
          })
          this.$store.commit('promocodes/SET_EDIT_PROMO', false);
          await this.$store.dispatch('promocodes/awaitGetPromocodes');
              } catch (error) {
                if (error.response.status === 409) {
                  this.$toast.add({ severity: 'error', summary: this.$t('promo_name_exist'), life: 4000 });
                } else{
                  this.$toast.add({
                    severity: 'error', summary: this.$t("just_error"), life: 4000
                  })
                }
              } finally{
                
                this.formLoading = false;
              }
            },
        
    },
}
</script>

<style lang="scss" scoped>
.input-menu{
    color: rgba(0,0,0,.87);
    border: 1px solid rgba(0,0,0,.38);
    border-radius: 8px;
    min-width: 170px;
      max-width: max-content;
    height: 40px;
    display: flex;
    justify-content: space-between;
    padding: 0 12px;
    cursor: pointer;
    align-items: center;
    position: relative;
    &__menu{
      background-color: var(--white);
      box-shadow: none;
      padding: 10px;
      z-index: 3;
      position: relative;
      width: 100%;
      //top: 0;
      //left: 0;
      //position: absolute;
      .v-input{
        margin-top: 8px;
        &:first-child{
          margin-top: 0px;
        }
      }
    }
  }
.v-card__title{
    text-transform: uppercase;
}
.form-block{
    //max-height: 214px;
    //overflow: auto;
    display: flex;
    flex-direction: column;
    span{
      display: flex;
      align-items: center;
    }
    .title-input {
      flex: 0 0 200px;
      display: flex;
      //justify-content: flex-end;
    }
    .wrapper-input {
      //flex: 0 0 200px;
     // width: 100%;
    flex: 1 1 auto;
    }
    
    .v-input{
      min-width: 170px;
      max-width: max-content;
        margin-right: 5px;
        //&:not(:last-child) {
        //}
        margin-bottom: 6px;
        &.error--text{
            margin-bottom: 0;
        }
    }
    &_height {
        flex-direction: column;
        align-items: stretch;
    }

    &__header {
    }

    &__inputs {
        display: flex;
        flex-wrap: wrap;
        .v-input{
            @media (minh:500px){
                max-width: 170px;
                
            }
        }
    }
}
.error-message-enter-active, .error-message-leave-active {
  transition: opacity 0.3s linear;
}

.error-message-enter, .error-message-leave-to {
  opacity: 0;
}

.error-message-leave-active {
  opacity: 0;
}

.error-message-enter-to, .error-message-leave {
  opacity: 1;
}
.error-message{
display: flex;
justify-content:center;align-items: center;
font-size: 14px;
color: var(--red);
font-weight: 600;
}

</style>